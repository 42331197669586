<template>
  <vue-picture-swipe
    :items="[
      {
        src: 'https://picsum.photos/200/200',
        thumbnail: 'https://picsum.photos/200/200',
        w: 600,
        h: 400,
        title: 'Will be used for caption',
      },
      {
        src: 'https://picsum.photos/300/200',
        thumbnail: 'https://picsum.photos/300/200',
        w: 1200,
        h: 900,
      },
    ]"
  ></vue-picture-swipe>
</template>


<script setup>
import { ref, computed } from "vue";
import VuePictureSwipe from "vue3-picture-swipe";

const yo = ref("yooo text");
const weiss = computed(() => {
  return "weiss";
});
const images = [
  "https://picsum.photos/200/200",
  "https://picsum.photos/300/200",
  "https://picsum.photos/250/200",
];
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
